// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-advancement-initiative-companies-tsx": () => import("./../../../src/pages/advancement-initiative/companies.tsx" /* webpackChunkName: "component---src-pages-advancement-initiative-companies-tsx" */),
  "component---src-pages-advancement-initiative-index-tsx": () => import("./../../../src/pages/advancement-initiative/index.tsx" /* webpackChunkName: "component---src-pages-advancement-initiative-index-tsx" */),
  "component---src-pages-advancement-initiative-partners-tsx": () => import("./../../../src/pages/advancement-initiative/partners.tsx" /* webpackChunkName: "component---src-pages-advancement-initiative-partners-tsx" */),
  "component---src-pages-best-managed-companies-playbook-tsx": () => import("./../../../src/pages/best-managed-companies-playbook.tsx" /* webpackChunkName: "component---src-pages-best-managed-companies-playbook-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-companies-tsx": () => import("./../../../src/pages/companies.tsx" /* webpackChunkName: "component---src-pages-companies-tsx" */),
  "component---src-pages-content-tsx": () => import("./../../../src/pages/content.tsx" /* webpackChunkName: "component---src-pages-content-tsx" */),
  "component---src-pages-ecommerce-shopify-saas-tsx": () => import("./../../../src/pages/ecommerce-shopify-saas.tsx" /* webpackChunkName: "component---src-pages-ecommerce-shopify-saas-tsx" */),
  "component---src-pages-environment-tsx": () => import("./../../../src/pages/environment.tsx" /* webpackChunkName: "component---src-pages-environment-tsx" */),
  "component---src-pages-esg-tsx": () => import("./../../../src/pages/esg.tsx" /* webpackChunkName: "component---src-pages-esg-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insights-tsx": () => import("./../../../src/pages/insights.tsx" /* webpackChunkName: "component---src-pages-insights-tsx" */),
  "component---src-pages-mental-health-tsx": () => import("./../../../src/pages/mental-health.tsx" /* webpackChunkName: "component---src-pages-mental-health-tsx" */),
  "component---src-pages-post-tsx": () => import("./../../../src/pages/post.tsx" /* webpackChunkName: "component---src-pages-post-tsx" */),
  "component---src-pages-privacy-tech-tsx": () => import("./../../../src/pages/privacy-tech.tsx" /* webpackChunkName: "component---src-pages-privacy-tech-tsx" */),
  "component---src-pages-research-advancement-initiative-tsx": () => import("./../../../src/pages/research-advancement-initiative.tsx" /* webpackChunkName: "component---src-pages-research-advancement-initiative-tsx" */),
  "component---src-pages-research-tsx": () => import("./../../../src/pages/research.tsx" /* webpackChunkName: "component---src-pages-research-tsx" */),
  "component---src-pages-restaurant-tech-tsx": () => import("./../../../src/pages/restaurant-tech.tsx" /* webpackChunkName: "component---src-pages-restaurant-tech-tsx" */),
  "component---src-pages-social-governance-tsx": () => import("./../../../src/pages/social-governance.tsx" /* webpackChunkName: "component---src-pages-social-governance-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-vc-diversity-pledge-tsx": () => import("./../../../src/pages/vc-diversity-pledge.tsx" /* webpackChunkName: "component---src-pages-vc-diversity-pledge-tsx" */),
  "component---src-templates-research-detail-tsx": () => import("./../../../src/templates/research-detail.tsx" /* webpackChunkName: "component---src-templates-research-detail-tsx" */)
}

